type UserCase =
  | 'not-logged-in'
  | 'logged-in-has-limit'
  | 'logged-in-exhausted-limit'
  | 'not-logged-in-free-file'
  | 'default';

export type PlanType = 'free' | 'pro' | 'premium';

interface UserMessages {
  [key: string]: string;
}

export const generateUserMessage = (
  userCase: UserCase,
  planType: PlanType,
  fileType: string,
  totalDownloaded: number,
  totalDownloadLimit: number
): string => {
  const baseMessages: UserMessages = {
    'not-logged-in-free-file':
      'Log in to access the Free plan and claim this template at no cost.',

    'logged-in-has-limit': `You have  ${
      totalDownloadLimit - totalDownloaded
    } downloads remaining. Your limit will reset in every ${
      planType === 'free' ? '8 hours' : '10 minutes'
    } and you can download ${totalDownloadLimit} every ${
      planType === 'free' ? '8 hours' : '10 minutes'
    }.`,

    'logged-in-exhausted-limit': `You have reached your limit of ${totalDownloadLimit} ${planType} files. Limits will reset in ${
      planType === 'free' ? '8 hours' : '10 minutes'
    }`,

    'not-logged-in': `Please sign in and subscribe to our Premium Plan for access to Premium files`,

    default: `To access ${
      fileType === 'FREE' ? fileType : 'Premium'
    } templates, please upgrade to a ${
      fileType === 'FREE' ? fileType : 'Premium'
    } Plan`,
  };

  return baseMessages[userCase];
};
