import {
  ICountryWiseItemPriceResponse,
  ICountryWiseItemPriceResponseData,
  IPAPIResponse,
} from 'libs/dt-types/response-types/pricing';
import { requestor } from 'libs/helpers/api/requestor';
import { IPlansResponse } from 'models/card/card.model';
import { PaymentLinkResponseModel } from 'models/card/paymentLinkResponse';

export const getPlans = async (currency: string) => {
  try {
    const response = await requestor.get<IPlansResponse>(
      '/payment-service/plans/v3',
      {
        headers: {
          'x-currency': currency,
        },
      }
    );

    return response;
  } catch (error: any) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const getPlansByCountryCode = async (countryCode: string) => {
  try {
    const response = await requestor.get<IPlansResponse>(
      `/payment-service/plans/v4/${countryCode}`
    );

    return response;
  } catch (error: any) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const getItemPriceById = async () => {
  try {
    const response = await requestor.get<IPAPIResponse>(
      '/users/get-user-location'
    );

    return response.data;
  } catch (error: any) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const getUserLocationDetails = async (ip: string) => {
  try {
    const response = await requestor.get<IPAPIResponse>(
      `/users/v2/get-user-location?ip=${ip}`
    );

    return response.data;
  } catch (error: any) {
    console.error('Error fetching order:', error);
    throw error;
  }
};

export const getPlansByName = async () => {
  try {
    const response: any = await requestor.get(`/payment-service/plans/name`);

    return response.data;
  } catch (error: any) {
    console.error('Error fetching plans:', error);
    throw error;
  }
};

export const getBillingPlansByCountryCode = async (countryCode: string) => {
  try {
    const response: any = await requestor.get(
      `/payment-service/plans//billing-plan/${countryCode}`
    );

    return response.data;
  } catch (error: any) {
    console.error('Error fetching plans:', error);
    throw error;
  }
};

export const createPaymentLink = async (planId: string) => {
  try {
    const response: PaymentLinkResponseModel = await requestor.post(
      '/payment-service/subscriptions/v3',
      {
        planId,
        callbackUrl: 'https://designtemplate.io/pricing',
      }
    );

    return response?.short_url;
  } catch (err) {
    console.error('Create plans:', err);

    throw err;
  }
};

export const getPremiumPlan = async (currency: string) => {
  try {
    const response: any = await fetch(
      `${process.env.API_BASE_URL}/payment-service/plans/premiumPlan`,
      {
        headers: {
          'x-currency': currency,
        },
      }
    );
    const plans = await response.json();

    return plans;
  } catch (error) {
    return [];
  }
};

export const getCountryWiseItemPricing = async (itemId: string) => {
  try {
    const response = (await requestor.get<ICountryWiseItemPriceResponse>(
      `/listing/items/get-item-price/${itemId}`
    )) as ICountryWiseItemPriceResponse;

    return response.data as ICountryWiseItemPriceResponseData;
  } catch (error) {
    console.error(error);
    throw new Error(`Error fetching getCountryWiseItemPricingWithError:`);
  }
};
